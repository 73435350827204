<template>
  <div class="search_filter_wrap2" style="margin-top: 10px;">
    <div class="search_item_wrap2">
      <div class="filter_category_box filter_bottom">
        <el-select id="category" v-model="searchOption" class="my_filter select"
                   popper-class="category_select"
                   :popper-append-to-body="false"
                   @change="setFilterFunc('isa', searchOption)"
        >
          <el-option :value="''" :label="$t('all')"></el-option>
          <el-option :value="'0'" :label="$t('answer_wait')"></el-option>
          <el-option :value="'1'" :label="$t('answer_finish')"></el-option>
        </el-select>
      </div>
      <div class="search_input2">
        <el-input :placeholder="$t('st')" v-model="searchInput"
                  @keypress.enter.native="setFilterFunc('su',searchInput)"/>
        <button class="search_btn" @click="setFilterFunc('su',searchInput)"><span>{{$t('search')}}</span>
          <img src="@/assets/image/main/search_btn.png"></button>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import Filter from "@/mixins/filter/filter"
import boardUtil from "@/components/board/boardUtil";

const {filterConfig} = boardUtil
export default {
    name: "BoardFilterQnaLayout",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort', 'setBoardListData'],
    provide() {
        return {}
    },
    props: {
      filterData: {default: () => {}},
      sortData: {default: () => {}},
      filterSet: {default: {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}}}
    },
    data() {
        return {
          searchOption: '',
          sortOption: 'updated_at',
          searchInput: '',
          filterClass: new Filter(),
          showFilter: false,
          isa: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
      this.filterClass.setterFilterConfigs(filterConfig);
      this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
      setterFilterDataInData() {
        let classnames = Object.keys(this.filterData);
        classnames.forEach(classname => {
          let obj = this.filterData[classname];
          let type = filterConfig[obj.key].type;
          if (this.hasOwnProperty(classname)) {
            // this[classname] =
            if (type == 'query') {
              this[classname] = obj.query;
              if (this.hasOwnProperty(classname + 'Option')) {
                this[classname + 'Option'] = obj.key;
              }
            } else if (type === 'between') {
              this[classname] = [obj.start, obj.end];
            } else if (type === 'match') {
              if (isNaN(obj.value)) {
                this[classname] = obj.value;
              } else {
                this[classname] = Number(obj.value);
              }

            } else if (type === 'terms') {
              this[classname] = obj.terms;
            }

          }
        })
        this.filterClass.filter = Object.assign({}, this.filterSet.filter);
        if(!util.isEmpty(this.sortData.key)) {
          this.sortOption = this.sortData.key;
        }
      },
      setFilterFunc(key, value) {
        this.setFilter(key, value);
        this.filterClass.filter = Object.assign({}, this.filterSet.filter);
      },
      setData(key, value) {
        this[key] = value;
      },
      runRangeFilter() {
        let filterSet = this.filterSet;
        filterSet.filter = this.filterClass.setterFilter('isa', this.searchOption);
        this.setBoardListData('filterSet', filterSet);
        this.setBoardListData('filterClass', this.filterClass);
        EventBus.$emit('boardListChangeSaveFilter')
        // this.showFilter = false;

      },
    },
    watch: {},
}
</script>

<style scoped>

</style>